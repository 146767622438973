import { Joi } from '../../../validation/rules';
import {Study} from '.';
import {event as newEvent} from '../../../executor';

const newDerivedEvent = (type, model, srcEvent, metadata) => newEvent(type, model, srcEvent, {timestamp: srcEvent.timestamp, ...srcEvent.metadata, ...metadata});

export default { // ordered list of event transformers (most recent versions at the end of array)
  [Number.MAX_SAFE_INTEGER]: (event, srcEvents) => { // Remove any test event at Study level which is for a test repeat older than current one (e.g. new event from retry 0 when previous events came from retry 1)
    if (event.aggregate.name === Study.name && event.data.tests !== undefined && event.type !== Study.events.STUDY_CANCELLED.type) {
      const tests = Object.entries(event.data.tests).filter(([sequence, test]) => !srcEvents.find(evt => {
        if (evt.timestamp > event.timestamp) return false;
        if (evt.aggregate.name !== Study.name) return false;
        if (!evt.data.tests?.[sequence]) return false;
        return (evt.data.tests?.[sequence]?.retry || 0) > (test.retry || 0);
      }));

      if (tests.length === 0 && Object.keys(event.data).length <= 2) return []; // Ignore this event
    }

    if (event.aggregate.name === Study.name && 
      (!event.data.patient?.instructions?.maxTestRepeats && event.data.patient?.instructions?.repeatOnInvalid) ||
      (event.data.patient?.instructions?.maxTestRepeats > 0 && !event.data.patient?.instructions?.repeatOnInvalid) ||
      (event.data.patient?.instructions?.repeatOnClientRequest === true && event.data.patient.instructions.repeatOnInvalid === false)
    ) {
      event.data.patient.instructions.repeatOnInvalid = true;
      
      if (event.data.patient?.instructions?.repeatOnClientRequest !== undefined) {
        delete event.data.patient.instructions.repeatOnClientRequest;
      }

      if (!event.data.patient?.instructions?.maxTestRepeats) {
        event.data.patient.instructions.maxTestRepeats = 1;
      }
    }

    if (event.aggregate.name === Study.name 
      && event.data.patient?.instructions?.maxTestRepeats !== undefined && event.data.patient?.instructions?.repeatOnInvalid === undefined) {
      event.data.patient.instructions.repeatOnInvalid = event.data.patient.instructions.maxTestRepeats > 0;
    } else if (event.aggregate.name === Study.name && event.data.patient?.instructions?.maxTestRepeats === undefined && 
        (event.data.patient?.instructions?.repeatOnInvalid !== undefined || event.data.patient?.instructions?.repeatOnClientRequest !== undefined)
      ) {
        event.data.patient.instructions.repeatOnInvalid = event.data.patient.instructions.repeatOnInvalid || event.data.patient?.instructions?.repeatOnClientRequest;
        
        if (event.data.patient?.instructions?.repeatOnInvalid === true || event.data.patient?.instructions?.repeatOnClientRequest === true) {
          event.data.patient.instructions.maxTestRepeats = 1;
        }
    }

    if (event.data.patient?.instructions?.repeatOnInvalid && event.data.patient?.instructions?.maxTestRepeats > 0) {
      event.data.patient.instructions.repeatsEnabled = true;
    }

    return event;
  },
  1654771707000: (event) => {
    if (event.aggregate.name === Study.name && event.data.patient?.instructions?.fillQuestionnaire === false) {
      event.data.patient.instructions.fillQuestionnaire = null;
    }

    return event;
  },
  1649282400000: (event) => { // 2021-04-06 22:00 - 1.6.27 (patient questionnaire adding multiple possible questionnaires)
    const Patient_v1 = require('./Patient').Patient_v1;

    if (event.aggregate.name === Study.name && ('patient' in event.data) && require('../../../executor/version').compare(event.metadata.schemaVersion || event.metadata.appVersion, Patient_v1.maxVersion) < 0) {
      event.data.patient = Patient_v1.toMaxVersion(event.data.patient);
    }

    return event;
  },
  1627948800000: (event) => { // 2021-08-03 00:00 - 1.5.4 (patient profile using custom fields)
    const Patient_v0 = require('./Patient').Patient_v0;

    if (event.aggregate.name === Study.name && 'patient' in event.data && Patient_v0.deprecatedKeys.some(field => field in event.data.patient)) {
      event.data.patient = Patient_v0.toMaxVersion(event.data.patient);
    }

    return event;
  },
  1618956000000: (event) => { // 2021.04.21.-1.2.11 (bugfixes of 1.2.10)
    if (event.aggregate.name === Study.name && event.type === Study.events.RECORDING_METADATA_UPDATED.type && event.data.tests?.[0]?.recording?.metadataFile?.endsWith('/report.json')) {
      return [];
    }

    if (event.aggregate.name === Study.name && event.type === Study.events.ANALYSIS_REPORT_UPDATED.type) {
      // Change those studies with histograms with more than n bar: reduce it to less bars
      const withBinSize = size => (bins, bin) => { 
        const x = Math.floor(parseInt(bin.x)/size);
        bins[x] = { x: `${x*size}`, value: (bins[x]?.value || 0) + bin.value };
        return bins;
      };

      Object.values(event.data.tests).forEach(test => {
        if (test.recording?.signals?.['Cardiac rate']?.histogram) test.recording.signals['Cardiac rate'].histogram = Object.values(test.recording.signals['Cardiac rate'].histogram.reduce(withBinSize(10), {}));
        if (test.recording?.signals?.['Resp rate']?.histogram)    test.recording.signals['Resp rate'].histogram    = Object.values(test.recording.signals['Resp rate'].histogram.reduce(withBinSize(3), {}));
      })
    }

    return event;
  },
  1618398007000: async (event) => { // 2021.04.14.-1.2.10 (multi-night testing)
    if (event.aggregate.name === Study.name) {
      if (event.type === Study.events.STUDY_CREATED.type && !('tests' in event.data)) {
        event.data.requestedTests = 1;
        event.data.tests = {"0": {filesPath: event.data.studyPath}}
      }
      if ('marker' in event.data) { // old MARKER_ADD/REMOVED events
        event.data.tests = {"0": {report: {markers: [event.data.marker]}}};
        delete event.data.marker;
      }
      if ('recording' in event.data) {
        event.data.tests = {'0': {...event.data.tests?.[0], recording: event.data.recording}};
        if ('signals' in event.data.tests[0].recording) Object.values(event.data.tests[0].recording.signals).forEach(signal => {
          signal.params.average = Math.round(signal.params.average);
          signal.params.stdDev  = Math.round(signal.params.stdDev);
          signal.histogram.forEach(bar => { bar.value = Math.round(bar.value * 100) });
        });
        delete event.data.recording;
      }
      if ('report' in event.data) {
        event.data.tests = {'0': {...event.data.tests?.[0], report: event.data.report}};
        if ('diagnosis' in event.data.tests[0].report) Object.values(event.data.tests[0].report.diagnosis).forEach(index => {
          index.value = Math.round(index.value);
          if ('events' in index) Object.values(index.events).forEach(e => {
            e.average = e.average.toFixed(1)
            e.percentage = (e.percentage * 100).toFixed(1)
          });
        });
        delete event.data.report;
      }
      if ('comments' in event.data) {
        event.data.tests = {'0': {...event.data.tests?.[0], clinicianComments: event.data.comments}};
        delete event.data.comments;
      }
      if ('comments' in (event.data.patient || {})) {
        event.data.tests = {'0': {...event.data.tests?.[0], patientComments: event.data.patient.comments}};
        delete event.data.patient.comments;
      }
      if ('requestedRecordings' in event.data) delete event.data.requestedRecordings;
      if ('recordings' in event.data) delete event.data.recordings;
    }
    return event;
  },
  1610236800000: async (event) => { // 2021.01.10-1.1.0
    if (event.type === "ACTIVATION_CODE_DELETED") return [];
    return event;
  },
  1605225600000: async (event, _allEvents) => { // 2020.11.13-0.1.181 
    if (event.type === "RECORDING_METADATA_UPDATED") {
      const newPayload = {};
      // Keep only the following fields whether present in original event
      if (event.data.recording?.metadataFile) newPayload.recording = {...newPayload.recording, metadataFile: event.data.recording.metadataFile};
      if (event.data.recording?.startTime)    newPayload.recording = {...newPayload.recording, startTime: event.data.recording.startTime};
      if (event.data.recording?.endTime)      newPayload.recording = {...newPayload.recording, endTime: event.data.recording.endTime};
      if (event.data.recording?.length)       newPayload.recording = {...newPayload.recording, length: event.data.recording.length};
      if (event.data.recording?.sensor)       newPayload.recording = {...newPayload.recording, sensor: event.data.recording.sensor};
      if (event.data.recording?.receiver)     newPayload.recording = {...newPayload.recording, receiver: event.data.recording.receiver};
      if (event.data.patient?.comments)       newPayload.patient   = {...newPayload.patient,   comments: event.data.patient.comments};
      if (event.data.patient?.sleepScore)     newPayload.patient   = {...newPayload.patient,   sleepScore: event.data.patient.sleepScore};
      if (event.data.comments)                newPayload.comments  = event.data.comments;
      
      event.data = newPayload;
    }

    if (event.type === "PATIENT_DETAILS_UPDATED") {
      if ('takesMedications' in (event.data?.patient || {}) && event.data.patient.takesMedications !== 'yes') event.data.patient.medications = null;
      if ('hasComorbidities' in (event.data?.patient || {}) && event.data.patient.hasComorbidities !== 'yes') event.data.patient.comorbidities = null;
    }

    return event;
  },
  1593475200000: async (event, srcEvents) => { // 2020.06.08-0.1.152-2
    const markerSchema = Joi.object().keys({
      signal   : Joi.string(),
      type     : Joi.string(),
      onset    : Joi.number().positive(),
      duration : Joi.number().positive()
    });
    
    const Study_0_1_180 = {
      ...Study,
      schema: Joi.object().keys({
        patient            : Joi.object().keys({
          id                 : Joi.string(),
          birthDate          : Joi.string().birthDate(),
          sex                : Joi.string().valid('male', 'female', 'unknown'),
          bmiMoreThan35      : Joi.string().valid('yes', 'no', 'unknown'),
          neckMoreThan40CM   : Joi.string().valid('yes', 'no', 'unknown'),
          highBloodPreassure : Joi.string().valid('yes', 'no', 'unknown'),
          takesBetaBlockers  : Joi.string().valid('yes', 'no', 'unknown'),
          takesMedications   : Joi.string().valid('yes', 'no', 'unknown'),
          medications        : Joi.string().when('takesMedications', { is: 'yes', then: Joi.required() }),
          hasComorbidities   : Joi.string().valid('yes', 'no', 'unknown'),
          comorbidities      : Joi.string().when('hasComorbidities', { is: 'yes', then: Joi.required() }),
          additionalInfo     : Joi.string().allow(''),
          instructions       : Joi.object().keys({
            providedPhone      : Joi.boolean().default(true),
            fillQuestionaire   : Joi.boolean()
          }),
          sleepScore         : Joi.number(),
          comments           : Joi.string().allow('')
        }),
        date               : Joi.string().isoDateWithOffset(),
        clinician          : Joi.string(), // User reference
        site               : Joi.string(), // HCS reference
        requestedRecordings: Joi.number().positive().min(1).max(10),
        recording          : Joi.object().keys({
          startTime     : Joi.string().isoDateWithOffset(),
          endTime       : Joi.string().isoDateWithOffset(),
          length        : Joi.number().positive().allow(0),
          diagnosableTime: Joi.number().positive().allow(0),
          signals       : Joi.object(), // TODO: specify further
          sensor        : Joi.string(),
          receiver      : Joi.string(),
          file          : Joi.string(), // TODO should be a relative URI ?
          metadataFile  : Joi.string(), // TODO should be a relative URI ?
          neckPhotoFile : Joi.string(), // TODO should be a relative URI ?
        }),
        report             : Joi.object().keys({
          markers    : Joi.array().items(markerSchema).unique(),
          signalsFile: Joi.string(),                               // TODO should be a relative URI ?
        }).unknown(true), // TODO: specify further
        status             : Joi.string().valid("CREATED", "INITIATED", "CONDUCTED", "ANALYSED", "COMPLETED", "INVALID"),
        // status             : Joi.i18nField(),
        hasConflict        : Joi.boolean(),
        activationCode     : Joi.object().keys({
          required: Joi.boolean(),
        }).unknown(true),
        studyPath          : Joi.string(),
        comments           : Joi.string().allow('')
      })
    };

    // TODO: include schema/model version in events
    if (event.type === "RECORDING_DETAILS_UPDATED") {
      const metadataFile = srcEvents.filter(e => e.aggregate.id === event.aggregate.id && 'file' in (e.data?.recording || {})).pop()?.data.recording.file.replace('-0.log', '.json')
      return newDerivedEvent('RECORDING_METADATA_UPDATED', Study_0_1_180, {...event, data: {...event.data, recording: {metadataFile, ...event.data.recording}}});
    }
  
    if (event.type === "STUDY_COMMENTED") return []; // Just ignore this event
    
    if (event.type === "STUDY_CREATED" && (!event.data.activationCode || !('required' in event.data.activationCode))) { // Second AND condition should not be necessary but we cannot guarantee the tiemstamp/date of the change
      event.data.activationCode = event.data.activationCode || {required: false};
      event.data.activationCode.required = Boolean((event.data.activationCode && event.data.activationCode.required) || event.data.generateCode);
      if ('generateCode' in event.data) delete event.data.generateCode;
      
      return event;
    }
  
    if (event.type === "STUDY_CONDUCTED") {
      const evts = [];
      const {recording: {file, ...metadata}} = event.data;
      
      const metadataIncluded = Object.values(metadata).length > 0; // Note: If NO metadata included, then this data will be provided in RECORDING_DETAILS_UPDATED (this event is what indicates metadata json file has been uploaded in previous versions) and hence we have to derive the recording.metadataFile from recording.file in this event
      let otherRecordingParams = undefined;
      if (!metadataIncluded) {
        const metadataFileExists = await require('../../../firebase').Storage.bucket().file(file.replace('-0.log', '.json')).exists();
        if (metadataFileExists) otherRecordingParams = {metadataFile: file.replace('-0.log', '.json')};
      }
      if (file !== undefined) evts.push(newDerivedEvent('RECORDING_FILE_UPDATED', Study_0_1_180, {...event, data: {...event.data, recording: {...event.data.recording, ...otherRecordingParams}}}));
      if (metadataIncluded) evts.push(newDerivedEvent('RECORDING_METADATA_UPDATED', Study_0_1_180, {...event, data: {id: event.data.id, recording: { metadataFile: file.replace('-0.log', '.json'), ...metadata }}}));

      return evts;
    }
    
    if (event.type === "STUDY_ANALYSED") {
      const evData = {...event.data};
      if (evData.recording && evData.recording.signals) {
        if ('Breathing Rate' in evData.recording.signals) {
          evData.recording.signals['Resp rate'] = evData.recording.signals['Breathing Rate'];
          delete evData.recording.signals['Breathing Rate'];
        }
        if ('Heart Rate' in evData.recording.signals) {
          evData.recording.signals['Cardiac rate'] = evData.recording.signals['Heart Rate'];
          delete evData.recording.signals['Heart Rate'];
        }
      }
      
      let signalsFile = undefined;
      if (evData.report && 'signalsFile' in evData.report) {
        signalsFile = evData.report.signalsFile;
        delete evData.report.signalsFile;
      }

      // New report errors interface
      if (evData.report && 'errors' in evData.report) {
        evData.report.code = evData.report.errors.length === 0 ? 'AC/000' : 'AC/001';
        evData.report.messages = evData.report.errors.map(e => ({
          code: e.code.startsWith('AC/ERRNO/3') ? `AC/WARNING/${e.code.split('/').pop()}` : `AC/ERROR/${e.code.split('/').pop()}`, 
          text: e.message
        }));
        delete evData.report.errors;
      }
      
      const newEvents = [newDerivedEvent('ANALYSIS_REPORT_UPDATED', Study_0_1_180, event)];
      if (signalsFile) newEvents.push(newDerivedEvent('SIGNALS_FILE_UPDATED', Study_0_1_180, {...event, data: { id: event.data.id, report: { signalsFile } }}));
      return newEvents;
    }

    return event;
  }
}