import { Joi } from '../../../validation/rules';
import { FORBIDDEN_POLICY, NOT_EXIST_POLICY, when } from '../../policies';
import { checkAttributeRights } from './policies';

let StudyModel;
const Study = () => {
  StudyModel ??= require('.').Study;
  return StudyModel;
}; // To avoid cyclic dependency

const OXIMETER_TYPES          = ['OxiPebble', 'Viatom', 'Either'];
const OXIMETER_LOCATIONS      = ['Finger', 'Forehead'];
const QUESTIONNAIRE_TYPES     = {epworth: "Epworth", none: "None"};
const shouldFillQuestionnaire = patient => Object.values(QUESTIONNAIRE_TYPES).filter(t => t !== QUESTIONNAIRE_TYPES.none).includes(patient?.instructions?.fillQuestionnaire);

const profileForm = {
  sex: {
    name    : { en: "Sex", es: "Sexo", it: "Sesso" },
    type    : "MULTIPLE_CHOICE",
    question: {
      en: "What was the patient's sex at birth?",
      es: "¿Cuál era el sexo del paciente al nacer?",
      it: "Qual'era il sesso del paziente al momento di nascita?"
    },
    options : [
      {label: {en: "Male", es: "Varón", it: "Maschio"}},
      {label: {en: "Female", es: "Hembra", it: "Femmina"}}
    ]
  },
  bmiMoreThan35: {
    name    : { en: "BMI", es: "IMC", it: "BMI" },
    type    : "MULTIPLE_CHOICE",
    question: {  
      en: "Is the patient's BMI more than 35?",
      es: "¿Tiene el paciente un IMC mayor de 35?",
      it: "Il BMI del paziente è superiore a 35?"
    },
    options : [
      {label: {en: "Yes", es: "Sí", it: "Sì"}},
      {label: {en: "No", es: "No", it: "No"}},
      {label: {en: "Don't know", es: "No lo sé", it: "Non lo so"}}
    ],
    default : 2
  },
  neckMoreThan40CM: {
    name    : {en: "Neck circumference", es: "Circunferencia del cuello", it: "Circonferenza del collo"},
    type    : "MULTIPLE_CHOICE",
    question: {en: "Neck circumference (range)", es: "Circunferencia del cuello (rango)", it: "Circonferenza del collo (intervallo)"},
    options : [
      {label: {en: ">40", es: ">40", it: ">40"}},
      {label: {en: "<=40cm", es: "<=40cm", it: "<=40cm"}},
      {label: {en: "Don't know", es: "No lo sé", it: "Non lo so"}}
    ],
    default : 2
  },
  highBloodPreassure: {
    name    : {en: "High blood pressure", es: "Tensión alta", it: "Pressione alta"},
    type    : "MULTIPLE_CHOICE",
    question: {en: "High blood pressure", es: "Tensión alta", it: "Pressione alta"},
    options : [
      {label: {en: "Yes", es: "Sí", it: "Sì"}},
      {label: {en: "No", es: "No", it: "No"}},
      {label: {en: "Don't know", es: "No lo sé", it: "Non lo so"}}
    ],
    default : 2
  },
  takesBetaBlockers: {
    name    : {en: "Takes beta blockers", es: "Toma bloqueantes beta", it: "Prende beta-bloccanti"},
    type    : "MULTIPLE_CHOICE",
    question: {en: "Takes beta blockers", es: "Toma bloqueantes beta", it: "Prende beta-bloccanti"},
    options : [
      {label: {en: "Yes", es: "Sí", it: "Sì"}},
      {label: {en: "No", es: "No", it: "No"}},
      {label: {en: "Don't know", es: "No lo sé", it: "Non lo so"}}
    ],
    default : 2
  },
  takesMedications: {
    name    : {en: "Medications", es: "Medicaciones", it: "Farmaci"},
    type    : "MULTIPLE_CHOICE",
    question: {
      en: "Would you like to add patient's medications?",
      es: "¿Desea añadir alguna medicación del paciente?",
      it: "Vuole aggiungere i farmaci usati dal paziente?"
    },
    options : [
      {
        label: {en: "Yes", es: "Sí", it: "Sì"}, 
        comments: { required: true , placeholder: {
          en: "Add medication...", es: "Añadir medicación...", it: "Aggiungere farmaci..."
        } }
      },
      {label: {en: "No", es: "No", it: "No"}},
      {label: {en: "The patient is not taking medication", es: "No toma medicación", "it": "Il paziente non sta assumendo farmaci"}}
    ],
    default : 1
  },
  hasComorbidities: {
    name    : {en: "Comorbidities", es: "Comorbilidades", it: "Comorbidità"},
    type    : "MULTIPLE_CHOICE",
    question: {
      en: "Would you like to add patient's comorbidities?", 
      es: "¿Desea añadir comorbilidades del paciente?", 
      it: "Vuole inserire le comorbidità del paziente?"
    },
    options : [
      {
        label: {"en": "Yes", "es": "Sí", "it": "Sì"}, 
        comments: { required: true , placeholder: {
          en: "Add comorbidities...", es: "Añadir comorbilidades...", it: "Aggiungere comorbidità..."
        } }
      },
      {label: {en: "No", es: "No", it: "No"}},
      {label: {en: "The patient does not have comorbidities", es: "No tiene comorbilidades", it: "Il paziente non ha comorbidità"}}
    ],
    default : 1
  },
  additionalInfo: {
    name       : {en: "Additional information", es: "Información adicional", it: "Informazioni ulteriori"},
    description: {en: "Additional patient information", es: "Información adicional del paciente", it: "Informazioni ulteriori del paziente"},
    type    : "PARAGRAPH_TEXT",
    question: {
      en: "Would you like to add additional information?",
      es: "¿Desea añadir información adicional?",
      it: "Vuole inserire ulteriori informazioni?"
    },
    placeholder: {en: "Additional information...", es: "Información adicional...", it: "Informazioni ulteriori..."}
  }
};

const Patient_v0 = {
  version: '1.0.0',
  maxVersion: '1.3.0',
  get deprecatedKeys() { return ['sex', 'bmiMoreThan35', Patient_v0.commercialFormKeys, Patient_v0.trialFormKeys]; },
  commercialFormKeys : ['takesMedications', 'medications', 'hasComorbidities', 'comorbidities', 'additionalInfo'],
  trialFormKeys      : ['neckMoreThan40CM', 'highBloodPreassure', 'takesBetaBlockers'],
  profileForm,
  getCustomField     : (oldKey) => profileForm[oldKey],
  fromMaxVersion: (newPatient={}) => {
    const {profile=[], ...patient} = newPatient;
    const valueTransforms = {
      sex               : value => value === 0 ? 'male' : value === 1 ? 'female' : 'unknown',
      bmiMoreThan35     : value => value === 0 ? 'yes' : value === 1 ? 'no' : 'unknown',
      neckMoreThan40CM  : value => value === 0 ? 'yes' : value === 1 ? 'no' : 'unknown',
      highBloodPreassure: value => value === 0 ? 'yes' : value === 1 ? 'no' : 'unknown',
      takesBetaBlockers : value => value === 0 ? 'yes' : value === 1 ? 'no' : 'unknown',
      takesMedications  : value => value === 0 ? 'yes' : value === 2 ? 'no' : 'unknown',
      hasComorbidities  : value => value === 0 ? 'yes' : value === 2 ? 'no' : 'unknown'
    }
    return Object.entries({
      sex               : valueTransforms.sex(profile.find(item => item.field.name.en === profileForm.sex.name.en)?.value),
      bmiMoreThan35     : valueTransforms.bmiMoreThan35(profile.find(item => item.field.name.en === profileForm.bmiMoreThan35.name.en)?.value),
      neckMoreThan40CM  : valueTransforms.neckMoreThan40CM(profile.find(item => item.field.name.en === profileForm.neckMoreThan40CM.name.en)?.value),
      highBloodPreassure: valueTransforms.highBloodPreassure(profile.find(item => item.field.name.en === profileForm.highBloodPreassure.name.en)?.value),
      takesBetaBlockers : valueTransforms.takesBetaBlockers(profile.find(item => item.field.name.en === profileForm.takesBetaBlockers.name.en)?.value),
      takesMedications  : valueTransforms.takesMedications(profile.find(item => item.field.name.en === profileForm.takesMedications.name.en)?.value),
      medications       : profile.find(item => item.field.name.en === profileForm.takesMedications.name.en)?.comments || null,
      hasComorbidities  : valueTransforms.hasComorbidities(profile.find(item => item.field.name.en === profileForm.hasComorbidities.name.en)?.value),
      comorbidities     : profile.find(item => item.field.name.en === profileForm.hasComorbidities.name.en)?.comments || null,
      additionalInfo    : profile.find(item => item.field.name.en === profileForm.additionalInfo.name.en)?.value,
      ...patient
    }).filter(([_, value]) => value !== undefined).reduce((p, [field, value]) => ({...p, [field]: value}), {}); // Filter fields that do not exists in the profile form (original snapshots from v0 could not contain undefined values)
  },
  toMaxVersion: (v0Patient) => {
    const {sex, bmiMoreThan35, neckMoreThan40CM, highBloodPreassure, takesBetaBlockers, takesMedications, medications, hasComorbidities, comorbidities, additionalInfo, ...otherFields} = v0Patient || {};
    const trialProfile = {sex, bmiMoreThan35, neckMoreThan40CM, highBloodPreassure, takesBetaBlockers};
    const isTrialProfile = [neckMoreThan40CM, highBloodPreassure, takesBetaBlockers].some(field => field !== 'unknown' && field !== undefined);
    const commercialProfile = {sex, bmiMoreThan35, takesMedications, hasComorbidities, additionalInfo};
    const valueTransforms = {
      sex               : value => value === 'male' ? 0 : value === 'female' ? 1 : undefined,
      bmiMoreThan35     : value => value === 'yes' ? 0 : value === 'no' ? 1 : value === 'unknown' ? 2 : undefined,
      neckMoreThan40CM  : value => value === 'yes' ? 0 : value === 'no' ? 1 : value === 'unknown' ? 2 : undefined,
      highBloodPreassure: value => value === 'yes' ? 0 : value === 'no' ? 1 : value === 'unknown' ? 2 : undefined,
      takesBetaBlockers : value => value === 'yes' ? 0 : value === 'no' ? 1 : value === 'unknown' ? 2 : undefined,
      takesMedications  : value => value === 'yes' ? 0 : value === 'unknown' ? 1 : value === 'no' ? 2 : undefined,
      hasComorbidities  : value => value === 'yes' ? 0 : value === 'unknown' ? 1 : value === 'no' ? 2 : undefined
    }
    return {
      profile: Object.entries(isTrialProfile ? trialProfile : commercialProfile).map(([field, value]) => ({
        field   : profileForm[field],
        value   : valueTransforms[field] ? valueTransforms[field](value) : value,
        comments: (field === 'takesMedications' && medications) || (field === 'hasComorbidities' && comorbidities) || undefined
      })),
      ...otherFields
    };
  },
  get schema() { // Schema used from 0.0.0 until 1.3.0
    return Joi.object({ 
      id                 : Joi.string().trim(),
      birthDate          : Joi.string().birthDate(),
      sex                : Joi.string().valid('male', 'female', 'unknown'),
      bmiMoreThan35      : Joi.string().valid('yes', 'no', 'unknown'),
      neckMoreThan40CM   : Joi.string().valid('yes', 'no', 'unknown'),
      highBloodPreassure : Joi.string().valid('yes', 'no', 'unknown'),
      takesBetaBlockers  : Joi.string().valid('yes', 'no', 'unknown'),
      takesMedications   : Joi.string().valid('yes', 'no', 'unknown'),
      medications        : Joi.when('takesMedications', { switch: [{is: 'yes', then: Joi.string().maxLines(20).required()}, {is: Joi.exist(), then: Joi.valid(null).default(null)}] }),
      hasComorbidities   : Joi.string().valid('yes', 'no', 'unknown'),
      comorbidities      : Joi.when('hasComorbidities', { switch: [{is: 'yes', then: Joi.string().maxLines(20).required()}, {is: Joi.exist(), then: Joi.valid(null).default(null)}] }),
      additionalInfo     : Joi.string().allow('').maxLines(50),
      instructions       : Joi.object().keys({
        providedPhone      : Joi.boolean(),
        fillQuestionaire   : Joi.boolean()
      }),
      sleepScore         : Joi.number()
    }).unknown(false).transform(Patient_v0.toMaxVersion);
  }
}

const Patient_v1 = {
  version: Patient_v0.maxVersion,
  maxVersion: '1.6.0',
  get deprecatedKeys() { return ['sleepScore']; },
  fromMaxVersion: (newPatient={}) => {
    const {instructions={}, questionnaireScore, ...patient} = newPatient;
    return {
      ...patient,
      instructions: {
        fillQuestionaire: shouldFillQuestionnaire(newPatient),
        providedPhone: instructions.providedPhone
      },
      sleepScore: shouldFillQuestionnaire(newPatient) ? questionnaireScore : undefined
    };
  },
  toMaxVersion: (v1Patient) => {
    const {instructions={}, sleepScore, ...otherFields} = v1Patient || {};
    instructions.providedPhone = instructions.providedPhone === undefined ? false : instructions.providedPhone;
    if (instructions?.fillQuestionaire !== undefined) {
      instructions.fillQuestionnaire = instructions?.fillQuestionaire === true ? 'Epworth' : undefined;
      delete instructions.fillQuestionaire;
    }

    return {
      instructions,
      questionnaireScore: sleepScore,
      ...otherFields
    };
  },
  get schema() { // Schema used from 1.3.0 until 1.6.0
    return Joi.object({ 
      id          : Joi.string().trim(),
      birthDate   : Joi.string().birthDate(),
      profile     : Joi.array().items(Joi.object().keys({
        field   : Joi.object().unknown(true),
        value   : Joi.alternatives([Joi.number(), Joi.string().allow("")]), // Number in case of multichoice (option index)
        comments: Joi.string()
      })),
      instructions: Joi.object().keys({
        providedPhone      : Joi.boolean(),
        fillQuestionaire   : Joi.boolean()
      }),
      sleepScore  : Joi.number()
    }).unknown(false).transform(Patient_v1.toMaxVersion);
  }
}

const transform = (patient, toVersion) => {
  const prevModels = [Patient_v1, Patient_v0]; // TODO: Generalise this algorithm for backwards compatibility (converts patient from current version to the original API version schema)
  return prevModels.filter(m => require('../../../executor/version').compare(toVersion, m.maxVersion) < 0).reduce((val, oldModel) => oldModel.fromMaxVersion(val), patient);
};

const Patient = {
  version: '1.6.0', // Not used atm: this would be the schema version which does not need to be == to app version
  OXIMETER_TYPES,
  OXIMETER_LOCATIONS,
  QUESTIONNAIRE_TYPES,
  shouldFillQuestionnaire,
  schema: Joi.object().keys({
    id          : Joi.string().uppercase().trim(),
    reference   : Joi.string().trim(),
    birthDate   : Joi.string().birthDate(),
    profile     : Joi.array().items(Joi.object().keys({
      field   : Joi.object().unknown(true),
      value   : Joi.alternatives([Joi.number(), Joi.string().allow("")]), // Number in case of multichoice (option index)
      comments: Joi.string()
    })),
    instructions: Joi.object().keys({
      // sleep tests
      repeatOnInvalid: Joi.boolean().meta({ feature: { enabled: true }, preferences: { id: 'StudyConfigSettings', group: 'enableRepeat', order: 2, owner: 'repeatOnInvalid', required: true } }),
      repeatOnOximetryFailure:  Joi.boolean().meta({ preferences: { id: 'StudyConfigSettings', group: 'enableRepeat', order: 2.1, owner: 'repeatOnInvalid', when: ['enableRepeat', { is: true, then: Joi.required(), otherwise: Joi.strip() }] } }).when('repeatOnInvalid', { not: true, then: Joi.strip() }),
      maxTestRepeats: Joi.number().integer().positive().allow(0).meta({ preferences: { id: 'StudyConfigSettings', group: 'enableRepeat', order: 2.2, owner: 'repeatOnInvalid', when: ['enableRepeat', { is: true, then: Joi.invalid(0).required(), otherwise: Joi.number().custom(_ => 0) }] } }),
      repeatsEnabled: Joi.boolean(),
      // oximetry
      requiresOximetry: Joi.boolean().meta({ feature: { enabled: true }, preferences: { id: 'StudyConfigSettings', group: 'oximetry', order: 3, owner: 'requiresOximetry' } }),
      oximeterType: Joi.string().valid(...OXIMETER_TYPES).meta({ preferences: { id: 'StudyConfigSettings', group: 'oximetry', order: 3.1, owner: 'requiresOximetry', when: ['oximetry', { is: true, then: Joi.required(), otherwise: Joi.strip() }] } }).when('requiresOximetry', { is: true, then: Joi.string().default('Viatom'), otherwise: Joi.strip() }),
      oximeterLocation: Joi.string().valid(...OXIMETER_LOCATIONS).meta({ preferences: { id: 'StudyConfigSettings', group: 'oximetry', order: 3.2, owner: 'requiresOximetry', when: ['oximeterType', { is: 'OxiPebble', then: Joi.required(), otherwise: Joi.strip() }] } }).when('oximeterType', { is: 'OxiPebble', then: Joi.string().default('Finger'), otherwise: Joi.strip() }).when('requiresOximetry', { not: Joi.valid(true), then: Joi.strip() }),
      // fulfilment
      providedPhone: Joi.boolean().meta({ feature: { enabled: true }, preferences: { id: 'StudyConfigSettings', group: 'providedPhone', order: 6, owner: 'providedPhone' } }),
      // Questionnaire
      fillQuestionnaire: Joi.string().valid(...Object.values(QUESTIONNAIRE_TYPES)).meta({ feature: { disabled: 'None' }, preferences: { id: 'StudyConfigSettings', group: 'questionnaire', order: 7, owner: 'fillQuestionnaire' } }),
      // Mobile app
      alarmDisabled: Joi.boolean(),
    }),
    questionnaire: Joi.array().items(Joi.object().unknown(true)), // TODO: using custom fields form
    questionnaireScore: Joi.number(),
  }),
  events: {
    PATIENT_DETAILS_UPDATED  : { },
    PATIENT_QUESTIONNAIRE_FILLED: { },
  },
  commands: {
    CREATE_STUDY: {
      get schema() {
        return Patient.schema.keys({
          instructions: Patient.schema.extract('instructions').keys({
            alarmEnabled: Joi.boolean().meta({ feature: { enabled: true }, preferences: { id: 'StudyConfigSettings', group: 'alarmEnabled', order: 8, owner: 'alarmEnabled' } }),
          }),
        }).required()
          .fork(['id', 'birthDate'], s => s.required())
          .fork('instructions', s => s
            .transform((instructions) => {
              if (!('alarmDisabled' in instructions) && ('alarmEnabled' in instructions)) instructions.alarmDisabled = !instructions.alarmEnabled;
              return instructions;
            }),
          ).alternativeVersions({
            // Whenever any of these are called the product will always be sa100, therefore the defaults will be allowed (no need to delete these defaults)
            [Patient_v0.maxVersion]: Patient_v0.schema.fork(['id', 'birthDate', 'instructions', 'instructions.fillQuestionaire'], s => s.required())
              .fork(['instructions.providedPhone'], s => s.default(true))
              .fork(['bmiMoreThan35', 'neckMoreThan40CM', 'highBloodPreassure', 'takesBetaBlockers', 'takesMedications', 'hasComorbidities'], s => s.default('unknown')),

            [Patient_v1.maxVersion]: Patient_v1.schema.fork(['id', 'birthDate', 'instructions', 'instructions.fillQuestionaire'], s => s.required())
              .fork(['instructions.providedPhone'], s => s.default(true))
          })
          .alter({
            csv: schema => schema.prefs({ abortEarly: false, noDefaults: true })
              .fork(['profile', 'questionnaireScore'], s => s.forbidden())
              .fork('instructions', s => s
                .fork(['requiresOximetry', 'providedPhone'], s1 => s1.truthy('Y', 'Yes').falsy('N', 'No').sensitive(false))
                .fork('fillQuestionnaire', s1 => Joi.alternatives().try(s1, Joi.boolean().falsy('N', 'No').sensitive(false).invalid(true).custom(_ => QUESTIONNAIRE_TYPES.none)).required())
              )
          })
      },
      transform
    },
    UPDATE_PATIENT_DETAILS: {
      checkPolicies: (request, study, _, { user: userSnap }) => Promise.all([
        when(!study).rejectWith(NOT_EXIST_POLICY(`Study ${request.id} does not exists`)).then(Promise.all([
          // TODO: mobile app allows you to edit study after it has been created, i.e. status >= IN_PROGRESS, maybe at this point we should only allow to edit certain fields?: when(Study().STATUS.compare(study.data.status, Study().STATUS.in_progress) >= 0).rejectWith(FORBIDDEN_POLICY(`Patient details can not be changed when the study is in '${study.data.status}' status.`)),
        ])),
        when(Object.keys(request.patient?.instructions || {}).filter(k => k !== "alarmEnabled").some(k => k in request.patient.instructions && request.patient.instructions[k] !== study.data.patient.instructions[k] && Study().STATUS.compare(study.data.status, Study().STATUS.finished) >= 0)).rejectWith(FORBIDDEN_POLICY("Study setting cannot be edited once the study is finished")),
        checkAttributeRights(study, request, userSnap.data.id),
      ]),
      get schema() {
        return Joi.object().keys({
          id: Study().schema.extract('id').required(),
          patient: Patient.schema.keys({
            instructions: Patient.schema.extract('instructions').keys({
              alarmEnabled: Joi.boolean().meta({ feature: { enabled: true }, preferences: { id: 'StudyConfigSettings', group: 'alarmEnabled', order: 10, owner: 'alarmEnabled' } }),
            }).transform((instructions) => {
              if (!('alarmDisabled' in instructions) && ('alarmEnabled' in instructions)) instructions.alarmDisabled = !instructions.alarmEnabled;
              return instructions;
            }),
          }).min(1).alternativeVersions({
            [Patient_v0.maxVersion]: Patient_v0.schema.min(1),
            [Patient_v1.maxVersion]: Patient_v1.schema.min(1)
          }).required(),
        });
      },
      get event() { return Patient.events.PATIENT_DETAILS_UPDATED },
      transform
    },
    FILL_PATIENT_QUESTIONNAIRE: {
      checkPolicies: (request, study) => when(!study).rejectWith(NOT_EXIST_POLICY(`Study ${request.id} does not exists`)).then(Promise.all([
        when(!shouldFillQuestionnaire(study.data.patient)).rejectWith(FORBIDDEN_POLICY("Questionnaire has not been requested by clinician for this study")),
      ])),
      get schema() {
        return Joi.object().keys({
          id: Study().schema.extract('id').required(),
          questionnaire: Patient.schema.extract('questionnaire').options({ presence: 'required' }).required(),
        });
      },
      event: async (action) => {
        const event = Study().events.PATIENT_QUESTIONNAIRE_FILLED.new(action);
        const score = action.data.questionnaire.reduce((sum, q) => sum + (('value' in q) ? q.value : 0), 0);
        event.data.patient = {questionnaire: action.data.questionnaire, questionnaireScore: score};
        return event;
      }
    },
  },
  queries: {
    GET: {
      transform
    },
    GET_PATIENT_PROFILE_FORM: {
      get schema() { return Joi.object().keys({site: Joi.string().uri()}); },
      newRequest: (args, metadata) => ({
        action: Study().queries.GET_PATIENT_PROFILE_FORM.new(args, metadata), // FIXME: ugly -> query defined at Test scope, but as withDefaults is applied to Study (not to Test), need to use Study() instead of Test to create a new instance of this query
        depends: []
      })
    }
  }
}

export { Patient, Patient_v0, Patient_v1 };