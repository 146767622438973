import * as path from 'path';

import { UNIQUE_FIELD_POLICY, FORBIDDEN_POLICY, when, userHasOwnership } from '../../policies';

import Config from '../config';
import AdministrationConfig from "../../administration/config";
import { Joi, mail } from '../../../validation/rules';
import { Patient } from './Patient';
import { Roles } from '../../../iam/roles';
import { Test } from './Test';
import moment from 'moment';
import { defaultQueries, withDefaults } from '../..';
import { hasRole } from '../../../iam';
import { Report } from './Report';
import { Product } from '../../administration/model';
import { checkClinicianOwnsSite, checkStudyProduct, checkAttributeRights, checkOwnerIdHasProduct, checkApplySignaturePolicies, checkDeleteSignaturePolicies, checkCancelReason, checkClinicianOwnsSiteUpdate } from './policies';
import { asRegex, getSchemaFields } from '../../administration/model/attributeRights';
import { SchemaExtractor } from '../../../validation/schemaExtractor';
import { hash } from '../../../helpers/crypto';
import { findLast } from '../../../helpers/utils';

const User           = () => require('../../authentication/model').User;
const Organisation   = () => require('../../administration/model').Organisation;
const HealthcareSite = () => require('../../administration/model').HealthcareSite;

const STATUS = { created: "CREATED", in_progress: "IN_PROGRESS", finished: "FINISHED", reviewed: "REVIEWED" };
STATUS.values  = _ => Object.values(STATUS).filter(v => typeof(v) !== 'function');
STATUS.indexOf = s => STATUS.values().indexOf(s);
STATUS.compare = (s1, s2) => STATUS.indexOf(s1) - STATUS.indexOf(s2);

const SLEEP_REPORT_TYPES = [
  'DETAILED', // Clinician
  'CONDENSED', // Condensed
  'SIMPLIFIED' // Patient
];

const STATUS_GROUP = {
  CREATED    : 'CREATED',
  IN_PROGRESS: 'IN_PROGRESS',
  FINISHED   : 'FINISHED',
  REVIEWED   : 'REVIEWED',
};
STATUS_GROUP.values  = _ => Object.values(STATUS_GROUP).filter(v => typeof(v) !== 'function');
STATUS_GROUP.indexOf = s => STATUS_GROUP.values().indexOf(s);
STATUS_GROUP.compare = (s1, s2) => STATUS_GROUP.indexOf(s1) - STATUS_GROUP.indexOf(s2);

const STUDY_STATUS_MAP = {
  ...STATUS.values().reduce((all, s) => ({...all, [s]: s}),{}),
}

const getStatusGroup = status => STUDY_STATUS_MAP[status];

const CANCEL_REASON = {
  PATIENT_DID_NOT_COMPLETE: 'PATIENT_DID_NOT_COMPLETE',
  STUDY_ERROR: 'STUDY_ERROR',
  REPEATED_STUDY: 'REPEATED_STUDY', // deprecated, we support existing values, but don't allow new ones
  REPEATED_STUDY_ACURABLE: 'REPEATED_STUDY_ACURABLE',
  REPEATED_STUDY_PATIENT: 'REPEATED_STUDY_PATIENT',
  OTHER: 'OTHER',
};

const MIN_TESTS = 1;
const MAX_TESTS = 10;

const fillStudyPath = async (event) => { // TODO: move to CREATE_SUTDY model? Joi extension for loading parent organisation ??
  const { dataFrom } = require('../../../executor/urn');
  const { executeAsAdmin: exec } = require('../../../executor/executor.server');

  const id     = dataFrom(event.data.id).id;
  const site   = await exec(HealthcareSite().queries.GET.newRequest({id: event.data.site}));
  const orgId  = dataFrom(Organisation().ownersFrom(site.data).shift()).id;
  const siteId = dataFrom(event.data.site).id;
  
  event.data.studyPath = `/${orgId}/${siteId}/${id}`;

  return event;
}

const initTests = (event, prevStudy) => {
  const studyPath = prevStudy?.data?.studyPath || event.data.studyPath;
  return Array.from({length: event.data.requestedTests}, (_, i) => i).reduce((tests, id) => { tests[id] = {filesPath: `${studyPath}/${id}`}; return tests; }, {});
}

const toCsvValue   = (value, options) => { // options should be a superset of Joi's validate options for convenience, but in this case we care mostly only in the "context" option
  const { quote='"', delimiter=",", noValue="" } = options?.prefs?.context || {}; 
  if (value === undefined || value === null) 
    return noValue;
  //TODO maybe papaparse already does this scaping ... to confirm
  value = `${value}`.trim().replace(quote, `${quote}${quote}`);
  return new RegExp(`[${quote}${delimiter}]|[^ -~]|^0\\d+$`).test(value) ? `${quote}${value}${quote}` : value;  // common support for automatic CSV import in excel, escaping non-printable and reserved chars and numbers with leading zeros
}

const analyseCancelledTestsField = Joi.boolean().meta({ feature: { enabled: true }, preferences: { id: 'StudyConfigSettings', group: 'analyseCancelledTests', order: 4, owner: 'analyseCancelledTests', help: true } });
const getPatientRef = (patient) => {
  return `P-${hash(patient.id+patient.birthDate, 8)}`.toUpperCase();
};

const toReference = (str) => str && `R-${str.substring(0, str.length / 2)}-${str.substring(str.length / 2)}`;
const conductedDate = (tests) => Object.values(tests).filter(t => t.recording?.endTime !== undefined || t.recording?.startTime !== undefined).map(t => t.recording?.startTime !== undefined ? t.recording?.startTime : t.recording?.endTime).map(t => moment(t).utcOffset(t)).sort((t1, t2) => moment(t1) - moment(t2)).pop()?.toISOString(true);
const lastEventUpdate = (studyEvents, eventFilters) => findLast(studyEvents, e => !eventFilters || eventFilters.some(type => type.isInstance(e.id)))?.timestamp;
const repeatsEnabled = (settings) => {
  return settings.maxTestRepeats > 0 && settings.repeatOnInvalid;
}

let Study = {
  context: Config.context,
  name   : 'Study',
  STATUS,
  STATUS_GROUP,
  SLEEP_REPORT_TYPES,
  CANCEL_REASON,
  getStatusGroup,
  conductedDate,
  lastEventUpdate,
  repeatsEnabled,
  getReference: (studyData) => !studyData ? toReference((Math.floor(Math.random()*90000000) + 10000000).toString()) // Generate a whole new random reference
                             : studyData.reference !== undefined ? studyData.reference // From 2.4.0 we store study reference in DB
                             : studyData?.activationCode?.value ? toReference(studyData.activationCode?.value.slice(0, 8).padStart(8, '0'))
                             : toReference(require('../../../executor/urn').dataFrom(studyData?.id).id?.substring(0, 10).replace('-', '').toUpperCase()), // Note for studies without act.code (old studies wrt v1.2.2) use study ID (first 10 characters are timestamp of ULID, if uuid, then replace '-' characters and put uppercase)
  // TODO: check clinician belongs to the HCS of this study
  schema: Joi.object().keys({
    reference      : Joi.string().regex(/R-\d+-\d+/),
    patient        : Patient.schema,
    date           : Joi.string().isoDateWithOffset(),
    clinician      : Joi.referenceOf(User()), // User reference
    site           : Joi.referenceOf(HealthcareSite()), // HCS reference
    requestedTests : Joi.number().integer().positive().min(MIN_TESTS).max(MAX_TESTS).meta({ feature: { enabledForAnyValue: true }, preferences: { id: 'StudyConfigSettings', group: 'requestedTests', order: 1, owner: 'requestedTests' } }),
    freezeTestCancelledStatus: Joi.boolean(),
    showAllSleepPositions: Joi.boolean().meta({ feature: { enabled: true }, preferences: { id: 'StudyConfigSettings', group: 'showAllSleepPositions', order: 5, owner: 'showAllSleepPositions', required: true } }),
    tests          : Joi.object().pattern(Test.idSchema, Test.schema),
    status         : Joi.string().valid(...STATUS.values()),
    activationCode : Joi.object().keys({
      required: Joi.boolean(),
      until   : Joi.string().isoDateWithOffset(),
      value   : Joi.string().regex(/^\d{6}(?:\d{2})?(?:\d{5})?$/) // allows 6 and 8 digits for backwards compatibility but expects 13 digits typically.
    }),
    studyPath      : Joi.string(),
    hasConflict: Joi.boolean(),
    clinicianAnalysis: Joi.string().allow('').maxLines(50).meta({ feature: { enabledForAnyValue: true }, preferences: { id: "StudyTemplateSettings", group: "clinicianAnalysis", order: 1, format: 'textarea', owner: 'clinicianAnalysis' }, commands: ["EDIT_CLINICIAN_ANALYSIS"] }),
    cancel: Joi.object().keys({
      reason: Joi.string().valid(...Object.values(CANCEL_REASON).filter(v => v !== CANCEL_REASON.REPEATED_STUDY)),
      customText: Joi.string(),
    }),
  }),
  reports: {
    letter: {
      template  : path.join(path.dirname(__dirname), 'templates', 'letter.html'),
      query     : (args) => Study.queries.GET_STUDY_LETTER.newRequest(args)
    },
    activity: {
      type : 'csv',
      query: args => Study.queries.GET_ACTIVITY_REPORT.newRequest(args)
    },
    activityLog: {
      type : 'csv',
      query: args => Study.queries.GET_ACTIVITY_LOG.newRequest({...args, options: {...args.options, flatten: true }})
    },
    activitySummary: {
      type : 'csv',
      query: args => Study.queries.GET_ACTIVITY_SUMMARY.newRequest({...args, options: {...args.options, flatten: true }})
    },
    activationCode: {
      type: 'isPDF',
      query: args => Study.queries.GET_ACTIVATION_CODE.newRequest(args),
  },
  },
  entities: { 
    get Test() {
      delete this.Test;
      this.Test = require('./Test').Test.asEntity(Study);
      return this.Test;
    },
    get Preferences() {
      delete this.Preferences;
      this.Preferences = require('../../system/model').Preferences(Study);
      return this.Preferences;
    },
  }, 
  snapshot: (event, prevStudy) => { // TODO: reverse arguments as prevSnap could made optional. @felipe ?
    // TODO: check clinician belongs to the HCS of this study
    const study = prevStudy || {data: {}, metadata: {}};
    const tests = Object.entries(event.data.tests || {})
                        .filter(([id, test]) => test !== undefined 
                                              && (!test.retry || test.retry >= study.data.tests?.[id].retry))
                        .reduce((ts, [id, testEventData]) => {
                          ts[id] = Test.snapshot(testEventData, { 
                            data: study.data.tests?.[id], 
                            metadata: { freezeTestCancelledStatus: study.data.freezeTestCancelledStatus } 
                          }, event, prevStudy);
                          ts[id].retry = Math.max(study.data.tests?.[id]?.retry || 0, testEventData.retry || 0);
                          
                          return ts;
                        }, {});
    
    // Leaving this for backwards compatibility
    if (event.type === Study.events.PATIENT_QUESTIONNAIRE_FILLED.type && event.data.patient?.questionnaireScore < (study.data.patient.instructions.questionnaireThreshold || 0)) { // Need to create an event handler for old studies to generate a STUDY_CANCELLED event (now there is an event handler that triggers the CANCEL_STUDY command)
      Object.entries(study.data.tests || {}).forEach(([id]) => tests[id] = {...tests[id], status: Test.STATUS.cancelled});
    } 
    
    // This block is here for legacy study events, new Test entities do not need this
    if (event.type === Study.events.STUDY_CREATED.type 
      && event.data.activationCode.required === false) 
      tests[0].status = Test.STATUS.started;
    if (event.type === Study.events.STUDY_INITIATED.type 
      && study.data.tests[0].status === Test.STATUS.pending) 
      tests[0] = {status: Test.STATUS.started};
    if (event.type === Study.events.RECORDING_COMPLETED.type) {
      Object.keys(event.data.tests || {})
            .map(t => parseInt(t))
            .forEach(t => {
              const currentTest = tests[t] || study.data.tests?.[t];
              if ((currentTest?.retry || 0) === (event.data.tests[t].retry || 0) && study.data.tests?.[t+1]?.status === Test.STATUS.pending) {
                tests[t+1] = {...tests[t+1], status: Test.STATUS.started};
              }
            });
    }
    // END BLOCK

    // Study state machine
    const isInProgress   = study.data.status  === Study.STATUS.in_progress || Object.values({...study.data.tests, ...tests}).some(test => Test.STATUS.compare(test.status, Test.STATUS.pending) > 0);
    const allTestsCompleted = Object.values({...study.data.tests, ...tests}).every(test => Test.STATUS.compare(test.status, Test.STATUS.analysed) >= 0); // Needed for old studies which do not have the MARKED_AS_FINISHED event
    const isFinished     = (allTestsCompleted && study.data.status  === Study.STATUS.finished) || allTestsCompleted || event.type === Study.events.MARKED_AS_FINISHED.type || event.type === Study.events.STUDY_CANCELLED.type;
    const isReviewed     = (allTestsCompleted && study.data.status  === Study.STATUS.reviewed && event.type !== Study.events.MARKED_AS_FINISHED.type) || event.type === Study.events.MARKED_AS_REVIEWED.type;
    const status         = isReviewed ? Study.STATUS.reviewed
                         : isFinished ? Study.STATUS.finished 
                         : isInProgress ? Study.STATUS.in_progress 
                         : Study.STATUS.created;
    
    const newConflict = study?.metadata.deleted && event.type !== Study.events.STUDY_DELETED.type && event.type !== Study.events.STUDY_CANCELLED.type;
    const hasConflict = event.type !== Study.events.STUDY_RESTORED && (study.data.hasConflict || newConflict);

    const patientRef = getPatientRef({ ...prevStudy?.data.patient, ...event.data.patient });
    
    const res = {
      data: {
        status,
        patient: {
          reference: patientRef,
          instructions: {
            repeatsEnabled: repeatsEnabled({ ...prevStudy?.data.patient?.instructions, ...event.data.patient?.instructions })
          }
        },
        tests, 
        hasConflict, 
        activationCode: {...(event.data.activationCode || prevStudy?.data.activationCode), disabled: Study.STATUS.compare(status, Study.STATUS.finished) >= 0}
      }, 
      metadata: {
        deleted: study?.metadata.deleted && event.type === Study.events.STUDY_DELETED.type
      }
    };
    if (event.data.owners && !event.data.owners.some(o => o.includes('/Product/'))) res.data.owners = [...event.data.owners, AdministrationConfig.products.sa100.id];
    if (event.data.patient?.id) res.data.patient.id = event.data.patient.id.toUpperCase();
    if (isFinished && Study.STATUS.compare(study.data.status, Study.STATUS.finished) < 0) {
      res.metadata.finishedDate = new Date(event.timestamp).toISOString();
    }
    // Sets requiresOnboarding to false for backwards compatibility with the mobile app
    if (!('requiresOnboarding' in (event.data.patient?.instructions || {}))) {
      res.data.patient.instructions ??= {};
      res.data.patient.instructions.requiresOnboarding = false;
    }

    return res;
  },
  checkPolicies: Patient.checkPolicies,
  events: {
    CLINICIAN_ANALYSIS_EDITED: { },
    STUDY_CREATED            : { snapshot: (e) => ({data: {owners: e.data.owners.some(o => o.includes('/Product/')) ? e.data.owners : [...e.data.owners, AdministrationConfig.products.sa100.id]}}) },
    BATCH_STUDY_CREATED      : { },
    STUDY_INITIATED          : { },
    STUDY_UPDATED            : { 
      label: "Study details updated",
      snapshot: (event, prevStudy) => ('requestedTests' in event.data) && prevStudy.data.requestedTests > event.data.requestedTests ? {data: { tests: {
        ...Object.keys(prevStudy.data.tests).reduce((removedTests, id) => id in event.data.tests ? removedTests : {...removedTests, [id]: undefined}, {}), // Remove old tests if new requestedTests has been decreased
      } }} : undefined
    },
    ACTIVATION_CODE_REFRESHED: {},
    STUDY_RESTORED           : { snapshot: () => ({data: {hasConflict: false}, metadata: {deleted: undefined}}) },
    STUDY_ARCHIVED           : { label: "module.diagnosis.be.model.STUDY_ARCHIVED", snapshot: () => ({metadata: { archived: true }})},
    STUDY_UNARCHIVED         : { label: "module.diagnosis.be.model.STUDY_UNARCHIVED", snapshot: () => ({metadata: { archived: false }})},
    STUDY_DELETED            : { snapshot: () => ({data: {activationCode: {disabled: true}}, metadata: { deleted: true }}) },
    STUDY_CANCELLED          : { 
      snapshot: (evt, prevStudy) => {
        const cancelledTests = Object.values(evt.data.tests || {}).filter(t => t.status === Test.STATUS.cancelled).length;
        return {
          data: { // making prevStudy optional because the frontend otherwise throws an error, as we now await for the snapshot to update when cancelling a study
            activationCode: { disabled: Object.values(prevStudy?.data.tests || {}).filter(t => Test.STATUS.compare(t.status, Test.STATUS.conducted) < 0).length === cancelledTests }
          }
        };
      } 
    },
    SIGNATURE_APPLIED: {},
    SIGNATURE_DELETED: {},
    MARKED_AS_REVIEWED: {
      label: 'Marked as reported',
    },
    MARKED_AS_FINISHED: {},
    ...Patient.events,
    ...Object.entries(Test.events).reduce((evs, [evType, evModel]) => ({
      ...evs,
      [evType]: {...evModel}
    }), {}),
  },
  commands: {
    ...Patient.commands,
    ...Test.commands,
    //TODO: create transfer ownership command
    //TODO: when transfer ownership check also that target clinician or owner comply with
    //        policy => study.clinician must have sudy manager role
    //        policy => study.owners must be healthcaresites ids
    CREATE_STUDY: {
      checkPolicies: (study, existingStudy, executor, { user: userSnap, apiVersion }) => Promise.all([
        when(existingStudy !== undefined).rejectWith(UNIQUE_FIELD_POLICY(`Study unique identifier ${study.id} already in use.`)),
        checkClinicianOwnsSite(executor.execute, study.clinician, study.site),
        // It's done like this because checkAttributeRights needs the study.productId to be set
        checkStudyProduct(executor.execute, study, apiVersion).then(() => checkAttributeRights(existingStudy, study, userSnap.data.id)),
      ]),
      get schema() { 
        delete this.schema;
        this.schema = Study.schema.keys({
          analyseCancelledTests: analyseCancelledTestsField,
        })
                    .fork('id',             s => s.default(() => Study.newURN()))
                    .fork('date',           s => s.default(() => moment().toISOString()))
                    .fork('site',           s => s.default((_, helpers) => {
                      const user = helpers?.prefs?.context?.user
                      const userSite = HealthcareSite().ownersFrom(helpers?.prefs?.context?.user)[0];
                      if (user && !userSite) throw new Error('Site required'); // This is happening in some cases because user is not given to validation context, need to think on a better approach
                      return userSite; 
                    }))
                    .fork('clinician',      s => s.default(Joi.ref('$user.id')))
                    .fork('owners',         s => s.default((parent) => parent.site ? [parent.site] : undefined))
                    .fork('activationCode', s => s.unknown(false).default({required: false}))
                    .fork('patient',        _ => Patient.commands.CREATE_STUDY.schema.required())
                    .transform((instructions) => {
                      if (!('freezeTestCancelledStatus' in instructions) && ('analyseCancelledTests' in instructions)) instructions.freezeTestCancelledStatus = !instructions.analyseCancelledTests;
                      return instructions;
                    })
                    .alter({ 
                      // TODO: define conventions for forms validation. One command per form ? using the command name as the alter key ?
                      form: schema => schema.prefs({ abortEarly: false, noDefaults: true })
                                            .fork(['id', 'patient.id', 'patient.birthDate'], _ => Joi.optional()),
                      defaults: schema => schema
                        .fork('requestedTests', s => s.default(1))
                        .fork(['freezeTestCancelledStatus'], s1 => s1.default(parent => typeof parent.analyseCancelledTests === "boolean" ? !parent.analyseCancelledTests : false))
                        .fork('patient', s => s.required()
                          .fork('instructions', sc => sc
                            .fork(['requiresOximetry'], s1 => s1.default(false))
                            .fork(['alarmDisabled'], s1 => s1.default(parent => typeof parent.alarmEnabled === "boolean" ? !parent.alarmEnabled : false))
                            .fork(['maxTestRepeats'], s1 => s1.default(1))
                          )
                        ),
                      csv: schema =>  { //TODO: can be generalised and reused for other commands/queries that accepts or generates CSV reports
                        const importFields = ['patient.id', 'patient.birthDate', 'requestedTests', 'patient.instructions.requiresOximetry', 'patient.instructions.providedPhone', 'patient.instructions.fillQuestionnaire', 'siteName', 'clinicianEmail'];
                        const exportFields = ['reference', ...importFields];

                        return schema
                              .keys({
                                siteName: Joi.string(),
                                clinicianEmail: mail,
                              })
                              .alter({
                                import: S => S.prefs({ abortEarly: false, noDefaults: true })
                                              .or('site', 'siteName')
                                              .or('clinician', 'clinicianEmail')
                                              .fork(['id', 'date', 'owners', 'activationCode'], s => s.forbidden())
                                              .fork(['requestedTests'], s => s.required())
                                              .fork(importFields, s => s.meta({ csv: { header: true } })),
      
                                export: S => S.keys({ reference: Joi.string().default(Study.getReference), })
                                              .fork(exportFields, s => s.meta({ csv: { header: true } }).custom(toCsvValue)), 
                              })
                      }
                    }).keys({
                      productId: Joi.referenceOf(Product),
                    });
        return this.schema;
      },
      event: async (action, _, { study, metadata }) => {
        const updatedAction = { ...action, data: study, metadata };
        const event = Study.events.STUDY_CREATED.new(updatedAction);
        await fillStudyPath(event);
        event.data.tests = await initTests(event);
        event.data.owners.push(updatedAction.data.productId);
        event.data.patient.instructions.repeatsEnabled = study.patient.instructions.repeatsEnabled;
        return event;
      },
      transform: (ev, toVersion) => ({...ev, data: {...ev.data, patient: Patient.commands.CREATE_STUDY.transform(ev.data.patient, toVersion)}, metadata: {...ev.metadata, schemaVersion: toVersion}})
      // TODO: policy: check site and clinician are not conflict, i.e. clinician belongs to site and clinician is study manager
    },
    CREATE_STUDY_BATCH: {
      checkPolicies: _ => when(true).rejectWith(FORBIDDEN_POLICY('Backend action not implemented yet, use webapp front end feature instead please')),
      // TODO: remove temporal policy and move the logic in front (which is triggering several CREATE_STUDY requests in parallel) to backend for this command
      get event() { return Study.events.STUDY_CREATED; },
    },
    COMMENT_STUDY: {  // TODO: deprecate once test commands fully migrated to Test entity
      label: "Comment test", // TODO: Changing command name implies change action-level permission ID: how can we avoid this??
      get schema() { return Joi.object().keys({
        'id'   : Study.schema.extract('id').required(),
        'tests': Study.schema.extract('tests').pattern(Test.idSchema, Joi.object().keys({
          'clinicianComments': Test.schema.extract('clinicianComments').required()
        })).required()
      }); },
      get event() { return Study.events.TEST_COMMENTED; },
    },
    CANCEL_STUDY: {
      checkPolicies: (req, study, _1, { user }) => Promise.all([
        when(!study || Object.values(study.data.tests).every(test => test.status !== Test.STATUS.pending && test.status !== Test.STATUS.started)).rejectWith(FORBIDDEN_POLICY(`Study ${req.id} cannot be cancelled. Only studies with some pending or started tests can be cancelled.`)),
        checkCancelReason(req, user),
      ]),
      get schema() {
        return Joi.object().keys({
          id: Study.schema.extract('id').required(),
          cancel: Study.schema.extract('cancel').keys({
            customText: Study.schema.extract('cancel.customText').when('reason', { is: Study.CANCEL_REASON.OTHER, then: Joi.required(), otherwise: Joi.strip() }),
          }).required(),
        });
    },
      event: async (action, prevStudy) => {
        const event = Study.events.STUDY_CANCELLED.new(action);
        Object.entries(prevStudy.data.tests).forEach(([key, test]) => { if (test.status === Test.STATUS.pending || test.status === Test.STATUS.started) event.data.tests = {...event.data.tests, [key]: {status: Test.STATUS.cancelled}}; });
        return event;
      }
    },
    DELETE_STUDY: { // TODO Deprecated: use CANCEL_STUDY instead
      checkPolicies: (req, study, _1, {user}) => Promise.all([
        when(!user.isFromAcurable()).rejectWith(FORBIDDEN_POLICY(`Only Acurable users can delete studies.`)),
        when(study.data.status !== Study.STATUS.created).rejectWith(FORBIDDEN_POLICY(`Study ${req.id} cannot be deleted. Only recently created studies can be deleted.`)),
      ]),
      get schema() { return Joi.object().keys({ id: Study.schema.extract('id').required() }); },
      get event() { return Study.events.STUDY_DELETED; }
    },
    RESTORE_STUDY: {
      get schema() { return Joi.object().keys({ id: Study.schema.extract('id').required() }); },
      get event() { return Study.events.STUDY_RESTORED; }
    },
    UPDATE_STUDY: {
      checkPolicies: (request, study, executor, { user: userSnap }) => Promise.all([
        // TODO: remove non-changed fields from request somehow and move these policies as schema rules direclty
        Patient.commands.UPDATE_PATIENT_DETAILS.checkPolicies(request, study, executor, { user: userSnap })
          .then(async () => await Promise.all([
            when(('requestedTests' in request) && request.requestedTests !== study.data.requestedTests && study.data.status !== Study.STATUS.created).rejectWith(FORBIDDEN_POLICY("Requested tests cannot be edited once some test of the study has been started")),
            when(('showAllSleepPositions' in request) && request.showAllSleepPositions !== study.data.showAllSleepPositions && Study.STATUS.compare(study.data.status, Study.STATUS.finished) >= 0).rejectWith(FORBIDDEN_POLICY("Study setting cannot be edited once the study is finished")),
            when(('freezeTestCancelledStatus' in request) && request.freezeTestCancelledStatus !== study.data.freezeTestCancelledStatus && Study.STATUS.compare(study.data.status, Study.STATUS.finished) >= 0).rejectWith(FORBIDDEN_POLICY("Study setting cannot be edited once the study is finished")),
            when('owners' in request && HealthcareSite().ownersFrom(request)[0] !== study.data.site).rejectWith(FORBIDDEN_POLICY("Healthcare site cannot be changed")),
          ])),
        checkAttributeRights(study, request, userSnap.data.id),
        checkClinicianOwnsSiteUpdate(executor.execute, request.clinician, study.data.clinician, study.data.site),
      ]),
      get schema() {
        return Joi.object().keys({
          id            : Study.schema.extract('id').required(),
          clinician     : Study.schema.extract('clinician'),
          owners        : Study.schema.extract('owners'),
          requestedTests: Study.schema.extract('requestedTests'),
          showAllSleepPositions: Study.schema.extract('showAllSleepPositions'),
          freezeTestCancelledStatus: Study.schema.extract('freezeTestCancelledStatus'),
          analyseCancelledTests: analyseCancelledTestsField,
          patient       : Patient.commands.UPDATE_PATIENT_DETAILS.schema.extract('patient'),
        })
        .or('patient', 'clinician', 'requestedTests')
        .transform((instructions) => {
          if (!('freezeTestCancelledStatus' in instructions) && ('analyseCancelledTests' in instructions)) instructions.freezeTestCancelledStatus = !instructions.analyseCancelledTests;
          return instructions;
        });
      },
      event: async (action, prevStudy) => {
        const event = Study.events.STUDY_UPDATED.new(action);
        if ('requestedTests' in action.data) event.data.tests = await initTests(event, prevStudy);

        return event;
      },
      transform: (ev, toVersion) => ('patient' in ev.data) ? ({...ev, data: {...ev.data, patient: Patient.commands.UPDATE_PATIENT_DETAILS.transform(ev.data.patient, toVersion)}, metadata: {...ev.metadata, schemaVersion: toVersion}}) : ({...ev, metadata: {...ev.metadata, schemaVersion: toVersion}})
    },
    REFRESH_ACTIVATION_CODE: {
      get schema() { return Joi.object().keys({'id': Study.schema.extract('id')}); },
      event: async (action, prevStudy, newExpDate) => {
        const event = Study.events.ACTIVATION_CODE_REFRESHED.new(action);
        event.data.activationCode = {...prevStudy?.data.activationCode, until: newExpDate};

        return event;
      }
    },
    EDIT_CLINICIAN_ANALYSIS: {
      get schema() { return Joi.object().keys({
        'id'   : Study.schema.extract('id').required(),
        'clinicianAnalysis': Study.schema.extract('clinicianAnalysis').required()
      }); },
      get event() { return Study.events.CLINICIAN_ANALYSIS_EDITED; }
    },
    ARCHIVE_STUDY: {
      checkPolicies: (_req, study) => when(Study.STATUS.compare(study.data.status, Study.STATUS.finished) < 0).rejectWith(FORBIDDEN_POLICY("Only finished studies can be archived.")),
      get schema() { return Joi.object().keys({ id: Study.schema.extract('id').required() }); },
      get event() { return Study.events.STUDY_ARCHIVED; }
    },
    UNARCHIVE_STUDY: {
      checkPolicies: (_req, study) => when(!study.metadata.archived).rejectWith(FORBIDDEN_POLICY("Study is not archived.")),
      get schema() { return Joi.object().keys({ id: Study.schema.extract('id').required() }); },
      get event() { return Study.events.STUDY_UNARCHIVED; }
    },
    APPLY_SIGNATURE: {
      checkPolicies: (req, study, executor, { user: userSnap }) => checkApplySignaturePolicies(req, study, userSnap, executor),
      get schema() { return Joi.object().keys({ id: Study.schema.extract('id').required() }); },
      event: (_action, snap, triggeredEvent) => {
        const currentEvent = Study.events.SIGNATURE_APPLIED.new({
          id: snap.aggregate.id,
        });
        return [currentEvent, triggeredEvent];
      },
    },
    DELETE_SIGNATURE: {
      checkPolicies: (req, study, _, { user: userSnap }) => checkDeleteSignaturePolicies(req, study, userSnap),
      get schema() {
        return Joi.object().keys({
          id: Study.schema.extract('id').required(),
        });
      },
      event: (_action, snap, triggeredEvent) => {
        const currentEvent = Study.events.SIGNATURE_DELETED.new({
          id: snap.aggregate.id,
        });
        return [currentEvent, triggeredEvent].filter(Boolean);
      },
    },
    MARK_AS_REVIEWED: {
      checkPolicies: (_req, study) => when(study?.data?.status !== Study.STATUS.finished).rejectWith(FORBIDDEN_POLICY("Only finished studies can be marked as reviewed")),
      get schema() {
        return Joi.object().keys({
          id: Study.schema.extract('id').required(),
        });
      },
      event: async (action) => {
        const event = Study.events.MARKED_AS_REVIEWED.new({ ...action.data, status: Study.STATUS.REVIEWED });
        return event;
      },
    },
    MARK_AS_FINISHED: {
      checkPolicies: (_req, study) => when(study?.data?.status !== Study.STATUS.reviewed).rejectWith(FORBIDDEN_POLICY("Only reviewed studies can be marked as finished")),
      get schema() {
        return Joi.object().keys({
          id: Study.schema.extract('id').required(),
        });
      },
      event: async (action) => {
        const event = Study.events.MARKED_AS_FINISHED.new({ ...action.data, status: Study.STATUS.FINISHED });
        return event;
      },
    },
  },
  queries: {
    ...Patient.queries,
    get GET() {
      delete this.GET;
      this.GET = {
        ...defaultQueries.GET(Study),
        transform: (study, toVersion) => ({
          ...study,
          data: {
            ...study.data,
            patient: Patient.queries.GET.transform(study.data.patient, toVersion)
          },
          metadata: {
            ...study.metadata,
            schemaVersion: toVersion
          }
        })
      }
      return this.GET;
    },
    VIEW_STUDY_SUMMARY: {
      // TODO: Implement query
    },
    FIND_SLEEP_STUDY: {
      // TODO: Implement query
    },
    // TODO: maybe requires refactor the following queries to be more related with UI/EXPORT views/projections
    GET_GENERAL_STUDY_REPORT: {
      roles: [Roles.superAdmin.id],
      get schema() { return Joi.object().keys({ id: Study.schema.extract('id').required() }); },
      newRequest: (args, metadata) => ({
        action : Study.queries.GET_GENERAL_STUDY_REPORT.new(args, metadata),
        depends: []
      })
    },
    GET_STUDY_LETTER: {
      get schema() { return Study.schema.keys({id: Joi.string()}).fork('id', schema => schema.required()); },
      newRequest: (args, metadata) => ({
        action : Study.queries.GET_STUDY_LETTER.new(args, metadata),
        depends: [Study.queries.GET_GENERAL_STUDY_REPORT.newRequest],
        transform: ([studyReport], requestUser) => {
          if (!hasRole(Organisation().roles[Organisation().queries.GET.type].id)(requestUser.data.roles)) delete studyReport.report.Organisation;
          if (!hasRole(User().roles[User().queries.GET.type].id)(requestUser.data.roles)) delete studyReport.Study.clinician;
          if (!hasRole(HealthcareSite().roles[HealthcareSite().queries.GET.type].id)(requestUser.data.roles)) delete studyReport.Study.site;
          
          return ({ 
            ...studyReport,
            Study: {id: studyReport.Study.id, requestedTests: studyReport.Study.requestedTests, reference: studyReport.Study.reference, activationCode: studyReport.Study.activationCode, patient: studyReport.Study.patient, clinician: studyReport.Study.clinician, site: studyReport.Study.site}
          })
        }
      })
    },
    GET_ACTIVITY_REPORT: {
      get schema() { 
        return studyReportFiltersSchema;
      },
      newRequest: (args, metadata) => ({
        action : Study.queries.GET_ACTIVITY_REPORT.new(args, metadata),
        depends: []
      })
    },
    GET_ACTIVITY_LOG: { 
      get schema() { 
        return studyReportFiltersSchema.keys({
          // add a new 'fields' attribute with the list of "columns" to return ?
          options: Joi.object().prefs({allowUnknown: true, stripUnknown: false})
        });
      },
      newRequest: (args, metadata) => ({
        action : Study.queries.GET_ACTIVITY_LOG.new(args, metadata),
        depends: []
      })
    },
    GET_ACTIVITY_SUMMARY: { 
      get schema() { 
        return studyReportFiltersSchema.keys({
          // add a new 'fields' attribute with the list of "columns" to return ?
          options: Joi.object().prefs({allowUnknown: true, stripUnknown: false})
        });
      },
      newRequest: (args, metadata) => ({
        action : Study.queries.GET_ACTIVITY_SUMMARY.new(args, metadata),
        depends: []
      })
    },
    EXPORT_SLEEP_STUDY: {
      get schema() { return Joi.object().keys({
        id:     Study.schema.extract('id').required(),
        report: Joi.object().keys({ 
          language: Joi.string().default('en'), 
          options: Joi.object().keys({
            html:  Joi.boolean().default(false),
            mode:  Joi.string().valid(...SLEEP_REPORT_TYPES).required(),
            // By default all study tests will be exported
            tests: Joi.array().items(Study.entities.Test.schema.extract('id')).when('mode', {
              is: 'CONDENSED',
              then: Joi.array().length(1).items(Study.entities.Test.schema.extract('id')),
              otherwise: Joi.array().items(Study.entities.Test.schema.extract('id')),
            })
          }).required()
        }).required()
      }); },
      newRequest: (args, metadata) => ({
        action : Study.queries.EXPORT_SLEEP_STUDY.new(args, metadata),
        depends: []
      })
    },
    GET_STUDY_CONFIG_SETTINGS_FORM: {
      checkPolicies: async (requestData, _1, executor, {user}) => requestData.ownerId && HealthcareSite().isReference(requestData.ownerId) && userHasOwnership(user, requestData.ownerId, executor),
      get schema() { 
        return Joi.object().keys({
          ownerId: Joi.alternatives().try(Joi.referenceOf(HealthcareSite()), Joi.referenceOf(Organisation()), Joi.referenceOf(Product)).required(),
          productId: Joi.referenceOf(Product).default(AdministrationConfig.products.sa100.id),
          userId: Joi.referenceOf(User()),
        });
      },
      newRequest: (args, metadata) => ({
        action : Study.queries.GET_STUDY_CONFIG_SETTINGS_FORM.new(args, metadata),
        depends: []
      })
    },
    GET_STUDY_TEMPLATE_SETTINGS_FORM: {
      checkPolicies: async (requestData, _1, executor, {user}) => requestData.ownerId && HealthcareSite().isReference(requestData.ownerId) && userHasOwnership(user, requestData.ownerId, executor),
      get schema() { 
        return Joi.object().keys({
          ownerId: Joi.alternatives().try(Joi.referenceOf(HealthcareSite()), Joi.referenceOf(Organisation()), Joi.referenceOf(Product)).required(),
          productId: Joi.referenceOf(Product).default(AdministrationConfig.products.sa100.id),
          userId: Joi.referenceOf(User()),
        });
      },
      newRequest: (args, metadata) => ({
        action : Study.queries.GET_STUDY_TEMPLATE_SETTINGS_FORM.new(args, metadata),
        depends: []
      })
    },
    GET_STUDY_SLEEP_REPORT_SETTINGS_FORM: {
      checkPolicies: async (requestData, _1, executor, {user}) => requestData.ownerId && HealthcareSite().isReference(requestData.ownerId) && userHasOwnership(user, requestData.ownerId, executor),
      get schema() { 
        return Joi.object().keys({
          ownerId: Joi.alternatives().try(Joi.referenceOf(HealthcareSite()), Joi.referenceOf(Organisation()), Joi.referenceOf(Product)).required(),
          productId: Joi.referenceOf(Product).default(AdministrationConfig.products.sa100.id),
        });
      },
      newRequest: (args, metadata) => ({
        action : Study.queries.GET_STUDY_SLEEP_REPORT_SETTINGS_FORM.new(args, metadata),
        depends: []
      })
    },
    GET_CREATE_STUDY_FORM: {
      checkPolicies: async (requestData, _1, executor, {user}) => Promise.all([requestData.ownerId && HealthcareSite().isReference(requestData.ownerId) && userHasOwnership(user, requestData.ownerId, executor), checkOwnerIdHasProduct(executor.execute, requestData.ownerId, requestData.productId)]),
      get schema() { 
        return Joi.object().keys({
          ownerId: Joi.alternatives().try(Joi.referenceOf(HealthcareSite()), Joi.referenceOf(Study)).default((_, helpers) => {
            const user = helpers?.prefs?.context?.user;
            const userSite = HealthcareSite().ownersFrom(helpers?.prefs?.context?.user)[0];
            // really ugly, the first time its executed it will be undefined, however the second time it will be properly called by the checkAndComplete function
            if (user && !userSite) throw new Error('Site required');
            return userSite;
          }),
          skipReadOnly: Joi.boolean(), // For the case we are repeating a study we want to skip the fields being read only
          formId: Joi.string().default("StudyConfigSettings"),
          productId: Joi.referenceOf(Product).default(AdministrationConfig.products.sa100.id),
        });
      },
      newRequest: (args, metadata) => ({
        action : Study.queries.GET_CREATE_STUDY_FORM.new(args, metadata),
        depends: []
      })
    },
    GET_ACTIVATION_CODE: {
      get schema() {
        return Joi.alternatives().try(
          ...[
            Joi.object().keys({
              id: Study.schema.extract('id').required(),
            }),
            Joi.object().keys({
              code: Joi.string().pattern(/^\d{8}$/).required(), // 8 digits code
            }),
          ].map(s => s.keys({
            format: Joi.string().valid('png', 'pdf').default('pdf'),
            language: Joi.string().default('en'),
          })),
        );
      },
      newRequest: (args, metadata) => ({
        action: Study.queries.GET_ACTIVATION_CODE.new(args, metadata),
        depends: [], // NECESSARY! Otherwise it sends the handler all the snapshots!
      }),
    },
  },
  get fieldsWithPermissions() {
    delete this.fieldsWithPermissions;
    this.fieldsWithPermissions = getSchemaFields(Study.commands.CREATE_STUDY.schema, "StudyConfigSettings", "StudyTemplateSettings")
      .reduce((acc, field) => {
        acc[field.field] = {
          field: field.field,
          regex: asRegex(field.field),
          // fieldsWithPermissions is used to generate the attribute rights so the first time roles won't exist
          get commandRoles() {
            return (SchemaExtractor.JoiSchemaDescriptor.getMetas(field.schema).commands || []).reduce((acc2, commandName) => {
              Study.commands[commandName].roles.forEach(r => acc2.push(r));
              return acc2;
            }, []);
          },
        };
        return acc;
      }, {});
    return this.fieldsWithPermissions;
  },
};

Study = withDefaults()(Study);

const studyReportFiltersSchema = Joi.object().keys({
  owners: Joi.array().items(Joi.string()), // urns
  productId: Joi.referenceOf(Product),
  createdDateStart: Joi.alternatives().try(Joi.date().iso(), Joi.date().timestamp()),
  createdDateEnd: Joi.alternatives().try(Joi.date().iso().min(Joi.ref('createdDateStart')), Joi.date().timestamp().min(Joi.ref('createdDateStart'))).when('createdDateStart', { is: Joi.valid(true).required(), then: Joi.required() }),
  conductedDateStart: Joi.alternatives().try(Joi.date().iso(), Joi.date().timestamp()),
  conductedDateEnd: Joi.alternatives().try(Joi.date().iso().min(Joi.ref('conductedDateStart')), Joi.date().timestamp().min(Joi.ref('conductedDateStart'))).when('conductedDateStart', { is: Joi.valid(true).required(), then: Joi.required() }),
  lastUpdatedDateStart: Joi.alternatives().try(Joi.date().iso(), Joi.date().timestamp()),
  lastUpdatedDateEnd: Joi.alternatives().try(Joi.date().iso().min(Joi.ref('lastUpdatedDateStart')), Joi.date().timestamp().min(Joi.ref('lastUpdatedDateStart'))).when('lastUpdatedDateStart', { is: Joi.valid(true).required(), then: Joi.required() }),
  statuses: Joi.array().items(Joi.string().valid(...STATUS_GROUP.values())),
  reference: Joi.string().empty(''),
  patientBirthDate: Joi.alternatives().try(Joi.date().iso(), Joi.date().timestamp()),
  testStatuses: Joi.array().items(Joi.string().valid(...Study.entities.Test.STATUS_GROUP.values())),
  testSeverities: Joi.array().items(Joi.string().valid(...Object.keys(Report.SEVERITIES_GROUPS))),
  requestedTests: Joi.number().min(1),
  clinicians: Joi.array().items(Joi.string()), // urns
  devices: Joi.array().items(Joi.string()),
  showArchived: Joi.boolean(),
  exclusiveArchived: Joi.boolean(),
  batchId: Joi.string(),
  language: Joi.string().default('en'),
});

export { Study };
