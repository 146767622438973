import { NOT_EXIST_POLICY, when } from '../../policies';

import Config from '../config';
import { Joi } from '../../../validation/rules';
import { Recording } from './Recording';
import { Report } from './Report';
import { Roles } from '../../../iam/roles';
import { chain } from '../../../promises';
import { dataFrom, roleURN } from '../../../executor/urn';
import { withDefaults } from '../..';
import { asRegex } from '../../administration/model/attributeRights';
import { SchemaExtractor } from '../../../validation/schemaExtractor';

const Study = () => require('.').Study; // To avoid cyclic dependency

const REPEAT_REASONS = {
  sensorFellOff: 'ACU-REPEAT-01', 
  invalidResult: 'ACU-REPEAT-02', 
  oximetryIssue: 'ACU-REPEAT-03'
};

const TEST_NOT_CONDUCTED         = (studyId, testId) => ({ code: 'TEST_NOT_CONDUCTED', reason: `Test ${testId} not conducted for study ${studyId}`, resolution: 'Conduct the test', details: "No recording files uploaded" });
const ANALYSIS_ALREADY_SCHEDULED = (studyId, testId) => ({ code: 'ANALYSIS_ALREADY_SCHEDULED', reason: `Analysis for test ${testId} already scheduled for study ${studyId}`, resolution: 'Please wait until the analysis has been completed' });
const TEST_ALREADY_ANALYSED      = (studyId, testId) => ({ code: 'TEST_ALREADY_ANALYSED', reason: `Test ${testId} already analysed for study ${studyId}`, resolution: 'Please check the test report.' });

const STATUS = { pending: "PENDING", started: "STARTED", conducted: "CONDUCTED", analysed: "ANALYSED", invalid: "INVALID", cancelled: "CANCELLED" };
STATUS.values  = _ => Object.values(STATUS).filter(v => typeof(v) !== 'function');
STATUS.indexOf = s => STATUS.values().indexOf(s);
STATUS.compare = (s1, s2) => STATUS.indexOf(s1) - STATUS.indexOf(s2);

const STATUS_GROUP = {
  PENDING    : 'PENDING',
  STARTED    : 'STARTED',
  COMPLETED  : 'COMPLETED',
  INVALID    : 'INVALID',
  CANCELLED  : 'CANCELLED'
}
STATUS_GROUP.values  = _ => Object.values(STATUS_GROUP).filter(v => typeof(v) !== 'function');
STATUS_GROUP.indexOf = s => STATUS_GROUP.values().indexOf(s);
STATUS_GROUP.compare = (s1, s2) => STATUS_GROUP.indexOf(s1) - STATUS_GROUP.indexOf(s2);

const TEST_STATUS_MAP = {
  [STATUS.pending]  : STATUS_GROUP.PENDING,
  [STATUS.started]  : STATUS_GROUP.STARTED, 
  [STATUS.conducted]: STATUS_GROUP.STARTED,
  [STATUS.analysed] : STATUS_GROUP.COMPLETED,
  [STATUS.invalid]  : STATUS_GROUP.INVALID,
  [STATUS.cancelled]: STATUS_GROUP.CANCELLED,
}
const getStatusGroup = status => TEST_STATUS_MAP[status];

let Test = {
  REPEAT_REASONS,
  STATUS,
  STATUS_GROUP,
  FEATURE_FLAGS: {
    REPEAT_ON_INVALID_TEST: {
      minVersion: '2.3.5',
      supportedClients: [
        {
          clientType: 'mobile',
          minVersion: '2.4.0'
        }
      ]
    },
    REPEAT_ON_OXIMETRY_FAILURE: {
      minVersion: '2.5.0',
      supportedClients: [
        {
          clientType: 'mobile',
          minVersion: '2.4.0'
        }
      ]
    },
    REPEAT_ON_CLIENT_REQUEST: {
      supportedClients: [
        {
          clientType: 'mobile',
          maxVersion: '2.4.0'
        }
      ]
    }
  },
  getStatusGroup,
  idSchema: Joi.string().regex(/(^[1-9][0-9]+$)|(^[0-9]$)/),
  schema: Joi.object().keys({
    patientComments  : Joi.string().allow('').maxLines(50),
    clinicianComments: Joi.string().allow('').maxLines(50),
    recording        : Recording.schema,
    report           : Report.schema,
    status           : Joi.string().valid(...Object.values(STATUS)),
    filesPath        : Joi.string(),
    sequence         : Joi.number().positive().allow(0),
    retry            : Joi.number().positive().allow(0),
    repeatReason     : Joi.array().items(Joi.string().valid(...Object.values(REPEAT_REASONS))),
  }),
  snapshot: (testEvent={}, prev, event, _prevStudy) => {
    // TODO: remove this double compatibility with Test entity event and study data format
    const testEventData = testEvent.data || testEvent;
    const eventType     = testEvent.type || event.type;
    const prevTest      = prev?.data || prev;
    const StudyAggregate = Study();
    const isStudyEvent  = testEvent.aggregate?.name !== StudyAggregate.entities.Test.name;
    // END 

    const isStarted   = prevTest?.status === Test.STATUS.started || eventType === StudyAggregate.entities.Test.events.TEST_STARTED.type || (eventType === StudyAggregate.entities.Test.events.TEST_CREATED.type && testEventData.retry > 0);
    // We want to keep tests as cancelled even if they are analysed if freezeTestCancelledStatus is set to true
    const isInvalid   = prevTest?.status === Test.STATUS.invalid && eventType !== StudyAggregate.entities.Test.events.ANALYSIS_REPORT_UPDATED.type || eventType === StudyAggregate.entities.Test.events.ANALYSIS_REPORT_UPDATED.type && testEventData.report.code !== "AC/000";
    const isAnalysed  = prevTest?.status === Test.STATUS.analysed && eventType !== StudyAggregate.entities.Test.events.ANALYSIS_REPORT_UPDATED.type || eventType === StudyAggregate.entities.Test.events.ANALYSIS_REPORT_UPDATED.type && testEventData.report.code === "AC/000";
    const hasMainRecFiles = (prevTest?.recording?.files || []).concat(testEventData.recording?.files).concat(prevTest?.recording?.file).concat(testEventData?.recording?.file).filter(Boolean).find(f => f.endsWith('log') || f.endsWith('.log.zip')) && (prevTest?.recording?.metadataFile || testEventData.recording?.metadataFile);
    const isConducted = prevTest?.status === Test.STATUS.conducted || eventType === StudyAggregate.entities.Test.events.RECORDING_COMPLETED.type || hasMainRecFiles;
    const isCancelled = eventType === StudyAggregate.events.STUDY_CANCELLED.type || eventType === StudyAggregate.entities.Test.events.TEST_CANCELLED.type || ((prev?.metadata?.freezeTestCancelledStatus || (!isInvalid && !isAnalysed && !isConducted)) && prevTest?.status === Test.STATUS.cancelled);
  
    const status      =  isCancelled ? Test.STATUS.cancelled :
                           isInvalid ? Test.STATUS.invalid   :
                          isAnalysed ? Test.STATUS.analysed  :
                         isConducted ? Test.STATUS.conducted :
                           isStarted ? Test.STATUS.started   : 
                                       Test.STATUS.pending;

    const delta = isInvalid ? { status, recording: { diagnosableTime: undefined } } : // workaround for old invalid studies which have this field set. Acucore now does not set this field for invalid studies any more.
                              { status }; // FIXME: This snapshot should be the complete test snapshot data (because the Study model stores tests as an array and snapshots.mergeDeep does not work with arrays) -> @diego should we model Study.tests as an object where key is the ID??

    if (eventType === StudyAggregate.events.TEST_CREATED.type && isStudyEvent && prevTest.retry !== testEventData.retry) {
      Object.keys(prevTest).forEach(prevField => {
        if (!(prevField in testEventData)) {
          delta[prevField] = undefined;
        }
      });
      delta.status = Test.STATUS.started;
    }
    
    return isStudyEvent ? delta : {data: delta};
  },
  events: {
    TEST_CREATED           : {},
    TEST_DELETED           : { metadata: {deleted: true} },
    TEST_STARTED           : {},
    TEST_CANCELLED         : {},
    TEST_REPEATED          : {},
    TEST_COMPLETED         : {},
    MAX_RETRIES_HIT        : {},
    TEST_ANALYSIS_SCHEDULED: {},
    TEST_COMMENTED         : { label: "Comments saved" },
    TEST_ARCHIVED          : { snapshot: () => ({metadata: { archived: true }})},
    TEST_UNARCHIVED        : { snapshot: () => ({metadata: { archived: false }})},
    ...Recording.events,
    ...Report.events
  }
};

// Test entity: TODO above Test code should be refactored into this entity (this simplified entity is a temporal workaround to implement test repeats without changing other parts of the existing code for release 1.7.0)
Test.asEntity = _ => {
  const testEntity = withDefaults()({
    STATUS,
    STATUS_GROUP,
    getStatusGroup,
    context : Config.context,
    name    : 'Test',
    idSchema: Joi.string().regex(/^(?:[0-9]|[1-9][0-9]+)-(?:[0-9]|[1-9][0-9]+)$/), // <Test idx>-<Retry count>
    sequenceFromId: (urn) => urn && urn.includes('/Test/') ? urn.split('/').pop().split('-')[0] : undefined,
    retryFromId: (urn) => urn && urn.includes('/Test/') ? urn.split('/').pop().split('-')[1] : undefined,
    schema  : Test.schema,
    snapshot: Test.snapshot,
    events  : {...Object.entries(Test.events).reduce((evs, [evType, evModel]) => ({
      ...evs,
      [evType]: {...evModel}
    }), {})},
    commands: {
      COMMENT_TEST: {
        get roles() { return [roleURN("comment_study", Study().context, Study().name)]; },
        get schema() { return Joi.object().keys({
          'id'   : Study().entities.Test.schema.extract('id').required(),
          'clinicianComments': Study().entities.Test.schema.extract('clinicianComments').required()
        }); },
        get event() { return Study().entities.Test.events.TEST_COMMENTED; }
      },
      SCHEDULE_TEST_ANALYSIS: {
        roles: [Roles.superAdmin.id],
        checkPolicies: ({id, force}, prevTest) => chain(
          () => when(prevTest === undefined).rejectWith(NOT_EXIST_POLICY(`Test ${id} does not exists`)),
          () => when(Test.STATUS.compare(prevTest.data.status, Test.STATUS.conducted) < 0).rejectWith(TEST_NOT_CONDUCTED(id, test)),
          () => when(Test.STATUS.compare(prevTest.data.status, Test.STATUS.analysed ) < 0  && prevTest.metadata.events.filter(e => dataFrom(e.id).type === Study().entities.Test.events.TEST_ANALYSIS_SCHEDULED.type).length > 0).rejectWith(ANALYSIS_ALREADY_SCHEDULED(id, test)),
          () => when(Test.STATUS.compare(prevTest.data.status, Test.STATUS.analysed ) >= 0 && !force).rejectWith(TEST_ALREADY_ANALYSED(id, test))
        ),
        get schema() { return Joi.object().keys({
          'id': Study().entities.Test.schema.extract('id').required(),
          'force': Joi.boolean().default(false)
        }); },
        get event() { return Study().entities.Test.events.TEST_ANALYSIS_SCHEDULED; },
      }
    },
    queries : Object.entries({
      GET_SIGNALS_SCREENSHOT: { 
        get schema() { return Joi.object().keys({ 
          id: Study().entities.Test.schema.extract('id').required(), 
          report: Joi.object().keys({ language: Joi.string().default('en') }),
          signal: Joi.string(), 
          options: Joi.object().prefs({allowUnknown: true, stripUnknown: false}), 
          metadata: Joi.object().prefs({allowUnknown: true, stripUnknown: false})
        }) },
        newRequest: (args, metadata) => ({
          action: Study().entities.Test.queries.GET_SIGNALS_SCREENSHOT.new(args, metadata), // FIXME: ugly -> query defined at Test scope, but as withDefaults is applied to Study (not to Test), need to use Study() instead of Test to create a new instance of this query
          depends: []
        })
      },
      RENDER_SIGNAL_BOXPLOT_CHART: {
        get schema() { return Study().entities.Test.queries.GET_SIGNALS_SCREENSHOT.schema },
        newRequest: (args, metadata) => ({
          action: Study().entities.Test.queries.RENDER_SIGNAL_BOXPLOT_CHART.new(args, metadata), // FIXME: ugly -> query defined at Test scope, but as withDefaults is applied to Study (not to Test), need to use Study() instead of Test to create a new instance of this query
          depends: []
        })
      },
      ...Recording.queries,
      ...Report.queries
    }).reduce((queries, [type, query]) => {
      // TODO: create script to rename this permission on existing roles: Using legacy study permissions (i.e. before migrationg queries from Study aggregate to Test entity)
      if (!('roles' in query)) query.roles = [roleURN(type.toLowerCase(), Study().context, Study().name)];
      queries[type] = query;
      return queries;
    }, {}),
    get fieldsWithPermissions() {
      delete this.fieldsWithPermissions;

      const signalsField = SchemaExtractor.JoiSchemaDescriptor.find(Test.schema, (field) => field.metadata?.preferences?.id === 'Signals');
      const signalsRelativePath = signalsField.path.join('.');
      this.fieldsWithPermissions = SchemaExtractor.JoiSchemaDescriptor.getKeys(signalsField.schema).reduce((acc, signalField) => {
        if (signalField.id === '.*') return acc;
        const absolutePath = signalsRelativePath + '.' + signalField.id;
        const permission = asRegex(absolutePath, signalField.schema.type === 'object');
        acc[absolutePath] = { field: absolutePath, regex: permission };
        return acc;
      }, {});

      return this.fieldsWithPermissions;
    },
  }, Study());

  testEntity.entities = {
    get Marker() {
      delete this.Marker;
      this.Marker = require('./Marker').asEntity(testEntity);
      return this.Marker;
    }
  }

  return testEntity;
};

export { Test };